import React from 'react'
import { Link } from 'gatsby'

const BannerAreaTwo = () => {
  return (
    <div className="banner-area">
      <div className="container-fluid">
        <div className="container-max">
          <div className="banner-item-content banner-item-ptb">
            <h2>Building Greater & Inclusive Future for Everyone</h2>
            <p>
              We offer the full spectrum of services to help organizations work better. Everything
              from creating standards of excellence to assessing how you’re doing, and helping you
              perform even better in future.
            </p>
            <div className="banner-btn">
              {/* <Link to='/about' className='default-btn btn-bg-two border-radius-50'>
                Learn More <i className='bx bx-chevron-right' />
              </Link> */}
              <Link to="/contact" className="default-btn btn-bg-one border-radius-50 ml-20">
                Get A Quote <i className="bx bx-chevron-right" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerAreaTwo
