import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const myQuery = graphql`
{
    strapiWorkingProcess {
        helpText
        headerText
        workingCard {
            id
            number
            flatIcon
            title
            shortDesc
        }
    }
  }
`
const WorkProcess = () => {
  const {
    strapiWorkingProcess: {
      helpText,
      headerText,
      workingCard
    }
  } = useStaticQuery(myQuery)

  return (
    <section className='work-process-area pt-100 pb-70'>
      <div className='container'>
        <div className='section-title text-center'>
          <span className='sp-color2'>{helpText}</span>
          <h2>{headerText}</h2>
        </div>

        <div className='row pt-45'>
          {workingCard.map(work => (
            <div className='col-lg-3 col-sm-6' key={work.id}>
              <div className='work-process-card'>
                <i className={work.flatIcon} />
                <h3>{work.title}</h3>
                <p>{work.shortDesc}</p>
                <div className='number'>{work.number}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default WorkProcess
