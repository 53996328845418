import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const myQuery = graphql`
  {
    strapiFeedbackHeader {
      helpText
      headerText
    }
    allStrapiFeedbacks {
      nodes {
        id
        name
        position
        longDesc
        image {
          url
        }
      }
    }
  }
`
const options = {
  loop: true,
  margin: 30,
  responsiveClass: true,
  nav: true,
  navText: ["<i class='bx bx-chevron-left'></i>", "<i class='bx bx-chevron-right'></i>"],
  dots: false,
  autoplay: true,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    992: {
      items: 1,
    },
  },
}

const TestimonialsArea = props => {
  const {
    strapiFeedbackHeader: { helpText, headerText },
    allStrapiFeedbacks: { nodes },
  } = useStaticQuery(myQuery)

  // arun: check for isLight & add clients-area-two
  return (
    <section className={`clients-area ${props.isLight && 'clients-area-two'} pt-100 pb-70`}>
      <div className="container">
        <div className="section-title text-center mb-40">
          <span className="sp-color2">{helpText}</span>
          <h2>{headerText}</h2>
        </div>

        <OwlCarousel className="clients-slider owl-theme" {...options}>
          {nodes.map((clientslider, index) => {
            return (
              <div className="clients-content" key={index}>
                <div className="content">
                  <img src={clientslider.image.url} alt="Client" />
                  <i className="bx bxs-quote-alt-left" />
                  <h3>{clientslider.name}</h3>
                  <span>{clientslider.position}</span>
                </div>
                <ReactMarkdown>{clientslider.longDesc}</ReactMarkdown>
              </div>
            )
          })}
        </OwlCarousel>
      </div>
      {!props.isLight && (
        <div className="client-circle">
          <div className="client-circle-1">
            <div className="circle" />
          </div>
          <div className="client-circle-2">
            <div className="circle" />
          </div>
          <div className="client-circle-3">
            <div className="circle" />
          </div>
          <div className="client-circle-4">
            <div className="circle" />
          </div>
          <div className="client-circle-5">
            <div className="circle" />
          </div>
          <div className="client-circle-6">
            <div className="circle" />
          </div>
          <div className="client-circle-7">
            <div className="circle" />
          </div>
        </div>
      )}
    </section>
  )
}

export default TestimonialsArea
