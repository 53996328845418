import React from 'react'
import Layout from '../components/_App/layout'
import Seo from '../components/_App/seo'
import TopHeader from '../components/Common/TopHeader'
import Navbar from '../components/_App/Navbar'
import WorkProcess from '../components/Index/WorkProcess'
import ServicesArea from '../components/Index/ServicesArea'
import Footer from '../components/_App/Footer'
import BannerAreaTwo from '../components/Services/BannerAreaTwo'
import BrandArea from '../components/Common/BrandArea'
import TestimonialsArea from '../components/Testimonials/TestimonialsArea'

const Services = () => {
  return (
    <Layout>
      <Seo
        title='Services'
        description='We offer the full spectrum of services to help organizations work better. Everything from creating standards of excellence to assessing how you’re doing, and helping you perform even better in future'
      />

      <TopHeader />

      <Navbar />

      <BannerAreaTwo />

      <WorkProcess />

      <ServicesArea />

      <TestimonialsArea />

      <BrandArea />

      <Footer />
    </Layout>
  )
}

export default Services
